<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex mb-4 align-items-center">
          <div class="mr-auto">
            <h4 class="title">Log Aktivitas</h4>
          </div>
        </div>
        <div class="card-table">
          <div class="row">
            <div class="col-3">
              <Datepicker
                :autoApply="true"
                :closeOnScroll="true"
                v-model="formData.date"
                placeholder="Pilih Tanggal"
                :format="format"
                :enableTimePicker="false"
                locale="id-ID"
                selectText="Pilih"
                cancelText="Batal"
                @cleared="clearDate()"
                @update:modelValue="
                  changeDate($event)
                "
              ></Datepicker>
            </div>
          </div>

          <div class="table-responsive mt-4">
            <table
              class="table table-bordered table-striped table-hover"
              id="tableLogAktifitas"
            >
              <thead>
                <tr>
                  <th style="width: 15%">Waktu</th>
                  <th style="width: 20%">Aktivitas</th>
                  <th style="width: 15%">Penanggung Jawab</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in list" :key="index">
                  <td>{{ formatDate(value.created_at) }}</td>
                  <td>{{ value.action }}</td>
                  <td>{{ value.nama_karyawan }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Datepicker from "vue3-date-time-picker";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import { ref } from "vue";
import $ from 'jquery'
import moment from "moment";
import "moment/locale/id";
import { cksClient } from '../../../helper';
import { get_History } from '../../../actions/dashboard';

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    Datepicker,
    SidebarDashboard,
    NavbarDashboard,

    // TableLite
  },
  data() {
    return {
      formData: {
        id_company: cksClient().get('_account').id_company,
        date: '',
      },
      search: "",
      dataTable: "",
      isSearch: false,
      list: [],
    };
  },

  created() {
    this.getList();
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  methods: {
    formatDate(string) {
      return moment(string).format("DD MMMM YYYY HH:mm:ss");
    },
    getList() {
      get_History(this.formData, (res) => {
        this.list = res.list;

        if (this.dataTable) {
            this.dataTable.destroy();
          }
          setTimeout(() => {
            this.dataTable = $("#tableLogAktifitas").DataTable({
              info: false,
              language: {
                emptyTable: "Tidak ada data",
              },
              order: [[0, 'desc']],
            });
            this.tooltip();
          }, 1000);
      })
    },

    changeDate(data) {
      if (data) {
        this.formData.date = moment(data).format("YYYY-MM-DD");
        this.getList();
      }
    },

    clearDate(){
      this.formData.date = '';
      this.getList();        
    }
  },
};
</script>

<style scoped></style>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>